@import "../variables";


.main-content {
  &.side-content {
    margin-left: $sidebar-width;
    position: relative;
    transition: none;
    height: 100%;
    padding: 0;
    bottom: 0;
    border-radius: 0;
    background-color: $gray-50;
  }

  &.collapsed-margin-left{
    margin-left: $sidebar-width-collapsed;
  }
  .inner-body {
    position: relative;
    margin-top: 50px;
    padding: 36px 30px;
    min-height: calc(100vh - $header-height);
  }
}


.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.page-header {
  display: block;

  &-container {
    margin-bottom: 15px;

    h4 {
      font-size: 24px;
    }
  }
}

.page-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;

  &-title-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 10px;


    h4 {
      color: $gray-900;
      font-style: normal;
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  }
}

.page-search {
  .input-group {
    margin-top: 0;
    min-width: 260px;
    z-index: 2;

    .input-group-text-left {
      left: 7px;
      top: 7px;
      padding-right: 0;
    }

    .form-control {
      height: 2.15rem;
      width: 100%;
      padding: 0.25rem 0.5rem 0.25rem 2.15rem;
      border-radius: 0.375rem !important;

      &:disabled {
        background-color: $gray-75;

        &::placeholder {
          color: $gray-300;
        }
      }
    }
  }
}

.customer-feedback {
  background-color: $gray-50;

  &-intro {
    > img {
      margin: 20px 0 0 80px;
    }
  }

  &-info {
    @extend .bg-primary-50;
    min-height: 170px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px 0;
  }
}

@media (max-width: 1270px) and (min-width: 1120px) {
  .page-search {
    .input-group {
      min-width: 12rem;
      width: 100%;
      z-index: 99;
    }
  }
}

@media (max-width: 1119px) {
  .page-header.main-page-header {
    display: block;
  }
}


@media (max-width: 991px) {
  .main-content {
    &.side-content {
      margin-left: 0;
    }
  }
}

@media (max-width: 768px) {
  .customer-feedback {
    &-intro {
      > img {
        display: block;
        margin: 15px auto 0;
      }
    }

    &-info {
      gap: 10px;

      h3 {
        font-size: 23px;
      }
    }
  }
}

@media (max-width: 620px) {
  .main-content {
    .inner-body {
      padding: 20px 10px;
    }
  }

  .page-header {
    display: block;

    &-title-container {
      h4 {
        font-size: 1.25rem !important;
        line-height: 1.5rem !important;
        letter-spacing: -0.025rem !important;
      }
    }
  }
}
