$sidebar-width: 19.5em;
$sidebar-width-collapsed: 4.65em;
$header-height: 5em;

/*Color variables*/
$primary: #EB5017;
$secondary: #64CCC5;
$info: #190482;
$success: #0F973D;
$warning: #ff9b21;
$danger: #CB1A14;
$dark: #3b4863;
$light: #E4F1FF;
$black: #000000;
$white: #ffffff;


/*neutral variables*/
$neutral-200: #E5E7EB;

/*gray variables*/
$gray-900: #101928;
$gray-800: #1D2739;
$gray-700: #344054;
$gray-600: #475367;
$gray-500: #667185;
$gray-400: #98A2B3;
$gray-300: #D0D5DD;
$gray-200: #E4E7EC;
$gray-100: #F0F2F5;
$gray-75: #F7F9FC;
$gray-50: #F9FAFB;

/*office-brown variables*/
$office-brown-900: #3E3838;
$office-brown-700: #645D5D;


/*primary variables*/
$primary-900: #711E00;
$primary-700: #AD3307;
$primary-600: #CC400C;
$primary-500: #F15412;
$primary-400: #F56630;
$primary-200: #FA9874;
$primary-100: #FCB59A;
$primary-75: rgba(252, 210, 194, 0.3);
$primary-50: #FFECE5;

/*secondary  variables*/
$secondary-400: #1671D9;
$secondary-300: #3D89DF;


/*warning  variables*/
$warning-500: #DD900D;
$warning-400: #F3A218;
$warning-300: #F7C02B;
$warning-75: #f8db90;

/*success variables*/
$success-700: #036B26;
$success-600: #04802E;
$success-500: #099137;
$success-400: #0F973D;
$success-300: #78B62A;
$success-200: #5FC381;
$success-50: #E7F6EC;

/*danger variables*/
$danger-600: #D8131F;
$danger-500: #CB1A14;
$danger-400: #D42620;
$danger-300: #EB6300;
$danger-200: #E26E6A;
$danger-50: rgba(253, 218, 218, 0.67);


/*Dark Theme*/
$dark-theme: var(--dark-theme);
$dark-body: var(--dark-body);
$dark-color: var(--dark-color);
$dark-border: var(--dark-border);
/*Dark Theme*/
