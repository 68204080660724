@import "../variables";

.border-none{
  border: none !important;
}

.border {
  border: 1px solid #E4E7EC !important;
}

.border-top {
  border-top: 1px solid #E4E7EC !important;
}

.border-end {
  border-right: 1px solid #E4E7EC !important;
}

.border-bottom {
  border-bottom: 1px solid #E4E7EC !important;
}

.border-start {
  border-left: 1px solid #E4E7EC !important;
}

.border-office-brown-900 {
  border-color: $office-brown-900 !important;
}

.border-white {
  border-color: $white !important;
}

.border-primary {
  border-color: $primary !important;
}

.border-primary-400 {
  border-color: $primary-400 !important;
}

.border-secondary {
  border-color: $secondary !important;
}

.border-success {
  border-color: $success !important;
}

.border-success-600 {
  border-color: $success-600 !important;
}

.border-success-500 {
  border-color: $success-500 !important;
}

.border-success-400 {
  border-color: $success-400 !important;
}

.border-info {
  border-color: $info !important;
}

.border-warning {
  border-color: $warning !important;
}

.border-danger {
  border-color: $danger !important;
}

.border-danger-500 {
  border-color: $danger-500 !important;
}

.border-danger-200 {
  border-color: $danger-200 !important;
}

.border-light {
  border-color: #F0F2F5 !important;
}

.border-dark {
  border-color: $dark !important;
}

.border-danger-500-left {
  border-left-color: $danger-500 !important;
}

.border-success-600-left {
  border-left-color: $success-600 !important;
}

.border-gray-100 {
  border-color: $gray-100 !important;
}

.border-gray-200 {
  border-color: $gray-200 !important;
}

.border-gray-300 {
  border-color: $gray-300 !important;
}

.border-gray-500 {
  border-color: $gray-500 !important;
}

.border-radius-rounded {
  border-radius: 0.75rem !important;
}

.border-width-2{
  border-width: 2px !important;
}
