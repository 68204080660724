@import "../variables";


.main-profile-menu,
.avatar {
  position: relative;
  padding: 0.5rem 0;
  margin: 0;
  flex-shrink: 0;
  border-radius: 12.5rem;

  >.main-img-user {
    outline: none;
    width: 32px;
    height: 32px;
  }
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
}

.feedback-avatar {
  width: 134px;
  height: 134px;
  border-radius: 50%;

  >img {
    display: block;
    margin: auto;
    width: 130px;
    height: 130px;
  }

  h1 {
    margin-top: 33px;
  }
}

.profile-avatar {
  width: 84px;
  height: 84px;
}

.bottom-right-image {
  &::after {
    content: " ";
    height: 21px;
    width: 21px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    border-radius: 50%;
    border: 1px solid $white;
    background: $gray-600 url("../../images/svgs/icons/camera-white.svg") no-repeat center;
  }
}

@media (max-width: 400px) {

  .main-profile-menu,
  .avatar {
    padding: 0.45rem 0;
    width: 2.2rem;
    height: 2.2rem;
  }

  .profile-avatar {
    width: 80px;
    height: 80px;
  }

  .feedback-avatar {
    width: 90px;
    height: 90px;

    >img {
      display: block;
      margin: auto;
      width: 80px;
      height: 80px;
    }

    h1 {
      font-size: 40px;
      margin-top: 13px;
    }
  }
}