@import "../variables";

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #4a526f;
  text-shadow: 0 1px 0 $white;

  &:hover {
    color: #8f9cc0;
    text-decoration: none;
  }
}

.modal{
  padding: 20px;

  &-lg, &-xl{
    --bs-modal-width: 800px;
  }

  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.45);

    &.fade {
      opacity: 0;
    }

    &.show {
      opacity: .8;
    }
  }

  &-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0.625rem;
    pointer-events: auto;
    background-color: $white;
    background-clip: padding-box;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12), 0 8px 16px 0 rgba(0, 0, 0, 0.24);
    border: none;
  }
}

.loader-modal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
}

.app-loader-modal {
  &:after {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
  }
}

@media (max-width: 500px) {
  .modal{
    padding: 5px;
  }
}
