@import "../variables";


.main-sidebar {
  &.side-menu {
    position: fixed;
    padding: 0.75rem 0;
    max-height: 100vh;
    z-index: 6;
    overflow-x: hidden;
  }

  &.main-sidebar-sticky {
    background: $white;
    //box-shadow: none;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: column;
    border-right: 1px solid $gray-75;
    box-shadow: 2px 0 10px lighten($gray-75, 30%);
    &.collapsed-width{
      overflow: visible;
    }
  }
}

.expanded-width {
  width: $sidebar-width;
}

.expanded-width-2 {
  width: 100%;
}

.collapsed-width {
  width: $sidebar-width-collapsed;
  transition: width 0.35s ease, visibility 0.35s ease;
}

.main-sidebar-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  padding: 0 0.5rem;
  .main-profile-menu {
    h6 {
      &::after {
        content: " ";
        height: 10px;
        width: 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        background-color: $success-600;
      }
    }
  }
}

.main-sidebar-body {
  display: flex;
  max-height: 80%;
  padding: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: content-box;
  scrollbar-width: thin;
  margin-bottom: 20px;
  border-bottom: 1px solid $gray-100;

  &.expanded-width-2 {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .nav {
    min-width: calc(100% - 1.25em);
    flex-direction: column;
    padding: 7px 0;

    .nav-submenu {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s;
    }

    &-link {
      color: $gray-600;
      font-size: 14px;
    }

    &-submenu-link {
      color: $gray-500;
      font-size: 14px;
      padding-left: 2.85rem !important;
    }

    &-link, &-submenu-link {
      display: flex;
      justify-content: space-between;
      line-height: 1.25rem;
      padding: 0.875rem 0.75rem;

      &.show, &:hover {
        color: $gray-900;
      }

      &-title {
        margin-left: 0.75rem;
      }

      img {
        margin-right: 0.75rem;
        margin-top: -0.1rem;
        width: 20px;
        height: 20px;
      }

      &.show + .nav-submenu.list-unstyled.show {
        max-height: 100vh;
        transition: all 2s;
      }

      &.show + .nav-submenu.list-unstyled.hide {
        max-height: 0;
        transition: all .5s;
      }
    }

    &-link.active-parent-menu {
      color: $gray-900;
    }

    li.nav-item {
      cursor: pointer;
      position: relative;
      margin-bottom: 5px;

      &.active {
        margin-right: 17.5px !important;
        border-radius: 5px !important;

        .nav-submenu-link {
          background-color: $primary-50;
        }

        .nav-link, .nav-submenu-link {
          color: $gray-900;
          font-weight: 500;
          position: relative;
          width: inherit;
          height: inherit;
        }
      }
    }
  }
}

.tooltip__content {
  position: absolute;
  left: 3.35em;
  top: 50%;
  transform: translateY(-50%);
  background-color: $primary-75;
  color: $gray-900;
  padding: 0.5em;
  border-radius: 5px;
  white-space: nowrap;
  width: fit-content;
  font-size: 14px;
  font-weight: 550;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease, background-color 3s ease, color 1.5s ease;
  z-index: 99999999999999999;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -13px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    visibility: hidden;
    border-style: solid;
    border-width: 10px 13px 10px 0;/* Arrow shape */
    border-color: transparent $primary-75 transparent transparent;
  }
}

.nav-item:hover .tooltip__content {
  visibility: visible;
  opacity: 1;
  &:before {
    visibility: visible;
  }
}

.main-sidebar-footer {
  background-color: $white;
  position: absolute;
  bottom: 0 !important;
  left: 0.5rem;
  right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 15px;
  max-height: calc(100% - 81.5%);
}

.footer-padding {
  padding: 15px 10px 10px;
}

@media (max-width: 991px) {
  .main-sidebar {
    &.side-menu {
      margin-top: calc($header-height - 15px);
      z-index: 999 !important;
    }
  }
  .main-sidebar-header{
    display: none;
    visibility: hidden;
  }
}

@media (max-height: 500px) {
  .main-sidebar-body {
    max-height: 72%;
  }
}

@media (max-width: 576px) {
  .main-sidebar-body {
    padding: 0.5px 0.525rem;
  }

  .nav {
    &-link, &-submenu-link {
      font-size: 13px;
    }
  }
  .main-sidebar-footer {
    border-top: 1px solid $gray-100;
  }
  .footer-padding {
    padding: 8px  10px;
  }
}
