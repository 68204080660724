@import "../variables";


.drop-zone {
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  margin-top: 8px;
  line-height: 22px;
  color: #334151;
  background-color: #FFF;
  background-image: none;
  text-align: center;
  border-radius: 1rem;
  border: 1.5px dashed $gray-300;
  -webkit-transition: border-color 0.15s linear;
  transition: border-color 0.15s linear;
  min-height: 280px;
  margin-bottom: 5px;
  padding: 1rem 1.5rem;
  flex-shrink: 0;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &:hover, &.dragover {
    background: $primary-50;
    border: 1.5px dashed $primary-500;
  }
}

.drop-zone-wrapper {
  width: 100%;
  padding: 0 1rem;
  text-align: center;
  color: $gray-600;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.26875rem;

  p {
    margin: 0.75rem 0 0.5rem 0;
    color: $primary-500;
    font-weight: 600;
    line-height: 1.26875rem;
  }

  .file-icon {
    content: "";
    width: 3.5rem;
    height: 3.5rem;
    flex-shrink: 0;

    display: inline-block;
    padding: 1.5rem;
    background: $gray-100 url("../../images/svgs/cloud.svg") no-repeat center;
    margin-right: 5px;
    border-radius: 50%;
  }
}

.drop-zone-message {

  h5 {
    color: $gray-400;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.45rem;
    margin-top: 1.25rem;
  }

  small {
    display: block;
    font-size: 0.75rem;
    color: $gray-400;
    text-align: center;
    font-style: normal;
    line-height: 1.0875rem;
  }
}

.drop-zone-divider {
  small {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;

    &:before,
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid $gray-100;
      margin: auto;
    }

    &:before {
      margin-right: 5px
    }

    &:after {
      margin-left: 5px
    }
  }
}


.drop-zone.file-upload-process, .drop-zone.file-upload-process:hover {
  border: 1.5px dashed $primary-200;
  background: #FFFBFA;
}

.drop-zone.file-upload-success, .drop-zone.file-upload-success:hover {
  border: 1.5px dashed $success-200;
  background: #FBFEFC;
}

.drop-zone.file-upload-error, .drop-zone.file-upload-error:hover {
  border: 1.5px dashed $danger-200;
  background: #fdf7f7;
}

.drop-zone.file-upload-error, .drop-zone.file-upload-success {
  p {
    color: $gray-800;
  }
}
@media (max-width: 500px) {
  .drop-zone-wrapper {
    padding: 0;
  }
}
