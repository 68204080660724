@import "../variables";

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}


.page-link, .page-link.active {
  color: $gray-400;
  border: none;
  font-weight: 400;
  position: relative;
  background-color: transparent;
  width: 1.5rem;
  height: 1.5rem;
  padding: .5rem .75rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.75rem;
  display: flex;
  line-height: 1.25;

  i {
    font-size: 30px;
    font-weight: 400;
    @extend .text-gray-800;
  }

  &:active, &.active, &.active:hover, .page-link.active, .active > .page-link {
    border-radius: 0.375rem;
    border: 1px solid $primary-400;
    z-index: 2;
  }

  &:hover, &:focus {
    background-color: transparent;
    box-shadow: none;
    z-index: 2;
  }

  &:hover {
    color: $primary-400;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
  }

  &.disabled {
    cursor: auto;
    pointer-events: none;
    background-color: $gray-75;
    i {
        @extend .text-gray-400;
      }
  }
}


.page-item {
  &.active .page-link {
    color: $white;
  }
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: $gray-400 !important;
  border-radius: 0.375rem;
  background-color: white;
  border: 1px solid $primary-400;
}

.page-item {
  &.previous .page-link, &.next .page-link {
    border-radius: 8px;
    padding: 0.5rem;
    height: 2.25rem;
    width: 2.25rem;
    border: 1px solid $gray-300;
  }
}

@media (max-width: 500px) {
  .page-link, .page-link.active {
    width: 1rem;
    height: 1rem;
    padding: 0.6rem;
    gap: 0.5rem;
  }
  .page-item {
    &.previous .page-link, &.next .page-link {
      height: 1.75rem;
      width: 1.75rem;
    }
  }
}
