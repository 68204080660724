@import "../variables";

.custom-switch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 10px;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding-left: 0;

  .custom-control-label {
    &::before {
      left: -2.25rem;
      width: 1.75rem;
      pointer-events: all;
      border-radius: 0;
    }

    &::after {
      top: calc(0.15625rem + 2px);
      left: calc(-2.25rem + 2px);
      width: calc(1rem - 4px);
      height: calc(1rem - 4px);
      background-color: #a8afc7;
      border-radius: 0;
      transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }

  .custom-control-input {
    &:checked ~ .custom-control-label::after {
      background-color: $white;
      transform: translateX(0.75rem);
    }

    &:disabled:checked ~ .custom-control-label::before {
      background-color: rgba(135, 96, 251, 0.5);
    }
  }
}

.custom-switch-description {
  color: $secondary-400;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.26875rem;
}

.custom-switch-sublabel{
  width: calc(100% - 2.25rem);
}

.custom-switch-input {
  position: absolute;
  width: 2.25rem !important;
  z-index: -1;
  opacity: 0;
  &:checked ~ .custom-switch-indicator {

    &:before {
      left: calc(1rem + 1px);
    }
  }

  &:focus ~ .custom-switch-indicator {
    box-shadow: none;
  }
}

.custom-switch-indicator {
  display: inline-block;
  height: 1.25rem;
  width: 2.25rem !important;
  background: #e5e9f3;
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid #f0f2f8;
  transition: .3s border-color, .3s background-color;

  &:before {
    content: '';
    position: absolute;
    height: calc(1.25rem - 4px);
    width: calc(1.25rem - 5px);
    top: 1px;
    left: 1px;
    background: $white;
    border-radius: 50%;
    transition: .3s left;
    box-shadow: none;
  }
}

.custom-switch-input:checked ~ .custom-switch-description {
  color: $gray-900;
}

.custom-switch-indicator-md {
  display: inline-block;
  height: 1.5rem;
  width: 2.8rem;
  background: #f5f6fb;
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid #ebeaf1;
  transition: .3s border-color, .3s background-color;
}

.custom-switch-indicator-md:before {
  top: 2px;
  height: calc(1.25rem - 4px);
  width: calc(1.25rem - 5px);
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
