@import "../variables";


.auth-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 100vh;
  padding: 20px;
  overflow: hidden;

  .auth-background {
    width: 50%;
    position: relative;
    border-radius: 1.875rem;
    box-sizing: border-box;
    background-size: cover;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    &-wrapper {
      width: 100%;
      padding: 3rem;

      .main-logo {
        width: 9.53656rem;
        height: 1.60975rem;
        object-fit: contain;
      }
    }

    &-hero {
      &-title {
        font-size: 3.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 4.125rem;
        letter-spacing: -0.075rem;
        margin-top: 1.5em;
      }

      &-description {
        min-width: 26rem;
        width: 65%;
        margin-top: 32px;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: lighter;
        line-height: 1.63125rem;
      }
    }

    &-footer {
      position: absolute;
      bottom: 3rem;
      right: 3rem;
      left: 3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;

      &-wrapper {
        padding: 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 1.25rem;

        p {
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.45rem;
        }

        img {
          width: 2.5rem;
          height: 2.5rem;
          object-fit: contain;
          padding: 0;
        }
      }
    }
  }

  .auth-form {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: center;

    &-wrapper {
      width: 100%;
    }

    &-header {
      margin-bottom: 20px;

      h2 {
        color: #1B1818;
      }
    }

    .form-control {
      width: 100% !important;
    }

    &-note {
      margin: 5rem 0 1rem 0;
    }
  }
}

.auth-verified {
  text-align: center;

  &-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .message-container {
    margin-top: 3.25rem;

    h4 {
      color: #1B1818;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.8rem;
      letter-spacing: -0.03rem;
    }

    .message {
      margin: 1.38rem 0;

      p {
        color: $office-brown-700;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.26875rem;
      }
    }
  }
}

@media (min-width: 1430px) {
  .auth-form {
    &-wrapper {
      padding: 0 9rem;
    }
  }
}

@media (max-width: 1430px) {
  .auth-form {
    &-wrapper {
      padding: 0 5rem;
    }
  }
}

@media (max-width: 1330px) {
  .auth-form {
    &-wrapper {
      padding: 0 3rem;
    }
  }

  .auth-background {
    background-position: 20% 35% !important;

    &-hero {
      &-title {
        //max-width: 27.75rem !important;
        font-size: 3.325rem !important;
        line-height: 3.75rem !important;
      }

      &-description {
        width: 20.75rem !important;
        font-size: 0.925rem !important;
        line-height: 1.475rem !important;
      }
    }

    &-footer-wrapper p {
      font-size: 0.825rem !important;
      line-height: 1.275rem !important;
    }
  }
}

@media (max-width: 1024px) {

  .auth-wrapper {
    display: flex;
    flex-direction: column;

    .auth-background {
      width: 100%;

      &-wrapper {
        padding: 2rem;
      }

      &-hero {
        &-title {
          max-width: 85%;
          margin-top: 40px;
          font-size: 2.25rem !important;
          line-height: 2.7rem !important;
        }

        &-description {
          min-width: 70%;
          margin-top: 20px;
          font-size: 0.75rem !important;
          line-height: 1.1rem !important;
        }
      }

      &-footer {
        display: none;
      }
    }

    .auth-form {
      width: 100%;

      &-wrapper {
        max-width: 550px;
        margin: 3rem auto 0 auto !important;
        display: block !important;
        padding: 0 !important;
      }
    }
  }
}

@media (max-width: 500px) {
  .form-check-label, a {
    font-size: 14px;
  }

  h2 {
    font-size: 2rem !important;
  }

  .auth-background-hero {
    &-title {
      max-width: 100%;
      margin-top: 20px;
      font-size: 1.7rem !important;
      line-height: 1.995rem !important;
    }

    &-description {
      max-width: 100%;
    }
  }

  .auth-form {
    &-header {
      margin-bottom: 20px !important;
    }
  }
}


@media (max-width: 450px) {
  .form-check-label, a {
    font-size: 12px;
  }
}
