@import "../variables";

.pills-header {
  @extend .text-gray-700;
  @extend .p-custom;
  @extend .border-bottom;
  @extend .font-weight-bolder;
  margin-bottom: 10px;
  padding-top: 0;
}

.nav-underline {
  border-bottom: 1px solid $gray-200;

  .nav-item {
    .nav-link, button {
      display: block;
      margin-right: 20px;
      padding: 16px 0;
      color: $gray-700;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.26875rem;
      text-decoration: none;
      background: none;
      border: 0;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

      &:hover,
      &:active,
      &:focus,
      &.active {
        color: $primary-400;
        border-bottom: 1px solid $primary-400;
      }
    }
  }
}

.nav-pills {
  .nav-link {
    display: block;
    color: $gray-600;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0;
    text-decoration: none;
    background: none;
    margin: 7px 0;
    border: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    min-height: 44px;
    border-radius: 4px;
    text-align: left;
    @extend .p-custom;
  }

  .nav-link.active,
  & .show>.nav-link {
    color: $gray-900;
    background-color: $primary-50;
  }

  .nav-link.disabled {
    color: $gray-500;
  }

  & .nav-link.disabled.active {
    background-color: $gray-75;
  }
}

@media (max-width: 769px) {

  .pills-header,
  .nav-pills .nav-link {
    text-align: center;
  }
}
