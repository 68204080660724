@import "../variables";
.lh-sm{
  line-height: 18px !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
  font-size: 14px;
  line-height: 1.66;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: $white !important;
}

.text-black{
  color: $black !important;
}

.text-gray-900 {
  color: $gray-900 !important;
}

.text-gray-800 {
  color: $gray-800 !important;
}

.text-gray-700 {
  color: $gray-700 !important;
}

.text-gray-600 {
  color: $gray-600 !important;
}

.text-gray-500 {
  color: $gray-500 !important;
}

.text-gray-400 {
  color: $gray-400 !important;
}

.text-gray-300 {
  color: $gray-300 !important;
}

.text-gray-200 {
  color: $gray-200 !important;
}

.text-gray-100 {
  color: $gray-100 !important;
}

.text-gray-75 {
  color: $gray-75 !important;
}

.text-neutral-200 {
  color: $neutral-200 !important;
}

.text-primary{
  color: $primary !important;
}

.text-primary-700 {
  color: $primary-700 !important;
}

.text-primary-500 {
  color: $primary-500 !important;
}

.text-primary-400 {
  color: $primary-400 !important;
}

.text-primary-200 {
  color: $primary-200 !important;
}

.text-secondary-400 {
  color: $secondary-400 !important;
}

.text-warning-500 {
  color: $warning-500 !important;
}

.text-warning-400 {
  color: $warning-400 !important;
}

.text-warning-300 {
  color: $warning-300 !important;
}

.text-success-600 {
  color: $success-600 !important;
}

.text-success-500 {
  color: $success-500 !important;
}

.text-success-300 {
  color: $success-300 !important;
}

.text-success-400 {
  color: $success-400 !important;
}

.text-danger-600 {
  color: $danger-600 !important;
}

.text-danger-500 {
  color: $danger-500 !important;
}

.text-danger-300 {
  color: $danger-300 !important;
}

.text-danger-200 {
  color: $danger-200 !important;
}

.text-office-brown-900 {
  color: $office-brown-900 !important;
}

.text-office-brown-700 {
  color: $office-brown-700 !important;
}

.tx-base {
  font-size: 0.875rem !important;
}

.tx-8 {
  font-size: 8px !important;
}

.tx-10 {
  font-size: 10px !important;
}

.tx-12 {
  font-size: 12px !important;
}

.tx-14 {
  font-size: 14px !important;
}

.tx-16 {
  font-size: 16px !important;
}

.tx-20{
    font-size: 20px !important;
}
.tx-30 {
  font-size: 30px;
}

.tx-48{
  font-size: 48px;
}

@media (min-width: 992px) {
  .tx-lg-36 {
    font-size: 36px;
  }

  .tx-48{
    font-size: 40px;
  }
}

@media (max-width: 500px) {
  .tx-48{
    margin-top: 10px;
    font-size: 28px !important;
  }
  .tx-sm-20{
    font-size: 16px !important;
  }
}
