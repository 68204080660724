@import "../variables";

.auth-black-bg-custom {
  background: linear-gradient(0deg, rgba(24, 24, 28, 0.88) 0%, rgba(24, 24, 28, 0.88) 100%), url("../../images/jpegs/auth-background.jpeg") no-repeat center;
}

.auth-gray-bg-custom {
  background: linear-gradient(0deg, rgba(237, 237, 237, 0.88) 0%, rgba(237, 237, 237, 0.88) 100%), url("../../images/jpegs/auth-background.jpeg") no-repeat center;
}

.bg-office-brown-900 {
  background: $office-brown-900;
}

.bg-white {
  background: #fff;
}

.bg-gray-100 {
  background: $gray-100 !important;
}

.bg-primary-50 {
  background: $primary-50 !important;
}

.bg-primary-75{
  background-color: $primary-75 !important;
}

.bg-primary-100 {
  background: $primary-100 !important;
}

.bg-primary-400{
  background-color: $primary-400 !important;
}

.bg-danger-500 {
  background-color: $danger-500 !important;
}

.bg-success-600 {
  background-color: $success-600 !important;
}

