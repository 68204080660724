@import "../variables";

.badge {
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.26875rem;
  display: inline-flex;
  padding: 0.125rem 0.75rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.75rem;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &-primary {
    color: $primary-400;
    background: $primary-50;
  }

  &-success {
    color: $success-700;
    background: $success-50;
  }

  &-error {
    color: $danger-500;
    background: $danger-50;
  }

  &-warning {
    color: $warning;
    background: $warning-75;
  }

  &-gray {
    color: $gray-500;
    background: $gray-100;
  }
}

