@import "../variables";


:root {
  --primary-bg-color: #EB5017;
  --primary-bg-hover: #F77E21;
  --primary-transparentcolor: #FFBB5C;
  --primary-bg-border: #EB5017;
  --dark-theme: #040D12;
  --dark-body: #001C30;
  --dark-border: rgba(255, 255, 255, 0.15);
  --dark-color: #d0d0e2;
  --dark-primary: #FCB59A;
  --indigo: #4b0082;
  --purple: #6f42c1;
  --pink: #f1388b;
  --orange: #EB5017;
  --yellow: #ffc107;
  --green: #64CCC5;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: $white;
  --gray: $gray-500;
  --gray-dark: $gray-900;
  --primary: #EB5017;
  --secondary: #6c757d;
  --success: #0F973D;
  --info: $info;
  --warning: $warning;
  --danger: $danger;
  --light: $light;
  --dark: $dark;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Inter", Roboto, sans-serif, "Helvetica Neue", Arial;
  --font-family-monospace: "Inter", Roboto, sans-serif, "Helvetica Neue", Arial;
}

* {
  box-sizing: border-box;

  &::before, &::after {
    box-sizing: border-box;
  }
}

html {
  font-family: "Inter", Roboto, sans-serif, "Helvetica Neue", Arial;;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: "Inter", Roboto, sans-serif, "Helvetica Neue", Arial !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.26875;
  text-align: left;
  background: $gray-75;
  color: $gray-900;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-font-feature-settings: "liga" 0;
  font-feature-settings: "liga" 0;
  //overflow-y: scroll;
  width: 100%;
  position: relative;
  scrollbar-width: none;
  scrollbar-color: #F56630 #FCD2C2 ;
}

a {
  font-style: normal;
  font-weight: 500;
  color: $secondary-400;
  text-decoration: none !important;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .h3-custom {
  font-style: normal;
  font-weight: 700;
  margin: 0;
}

h1, .h1 {
  font-size: 3.75rem;
  line-height: 4.125rem;
  letter-spacing: -0.075rem;
}

h2, .h2 {
  font-size: 2.25rem;
  line-height: 2.7rem;
  letter-spacing: -0.09rem;
}

h3, .h3 {
  font-size: 1.75rem;
  line-height: 2.1rem;
}

.h3-custom {
  font-size: 1.5rem;;
  line-height: 1.8rem;
}

h4, .h4 {
  font-size: 1.25rem;
  line-height: 1.45rem;
}

h5, .h5 {
  font-size: 1.125rem;
  line-height: 1.53125rem;
}

h6, .h6 {
  font-size: 1rem;
  line-height: 1.45rem;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

[type="number"] {
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    height: auto;
  }
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

//SCROLLBAR DESIGN
/* width */
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-button{
  display: none;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background:#F56630;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 3px;
  background: #F56630;
}


[hidden] {
  display: none !important;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

button {
  border-radius: 0;
  text-transform: none;

  &:focus {
    outline: none;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1000px;
  }
}
