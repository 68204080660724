@import "../variables";

.form-label {
  display: block;
  margin: 3px 0;
  color: $gray-900;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.0875rem;
  letter-spacing: -0.00375rem;
}

.form-note {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: $gray-500;
  line-height: 1.0875rem;
  margin: 3.75px !important;
}

.form-control, select {
  width: 100% !important;
  font-size: 0.875rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.26875rem;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $gray-300;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 6px;
  color: $gray-900;
  height: 3.5rem;
  padding: 0.5rem 0.75rem;
  margin-bottom: 5px;

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:focus {
    outline: 0;
    border-color: $primary-200;
    box-shadow: none;
  }

  &::placeholder {
    font-size: 13px;
    color: $gray-400 !important;
    opacity: 1;
  }

  .css-1jqq78o-placeholder {
  }


  &:disabled,
  &[readonly] {
    opacity: 1;
    background-color: $gray-100;
    color: $gray-500;
  }

  &.is-invalid {
    border: 1px solid $danger-400;

    &:focus {
      box-shadow: none;
    }
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: url("../../images/svgs/icons/cancel-outline.svg");
  background-position: right 15px center;
}

.form-control-sm {
  height: 36px !important;
  padding: 0.5rem 0.25rem;
}

// .custom-rounded-chekbox {
//   width: 18px;
//   height: 18px;
//   border-radius: 50%;
//   vertical-align: middle;
//   border: 1.5px solid $gray-200;
//   appearance: none;
//   -webkit-appearance: none;
//   cursor: pointer;
//   accent-color: $primary-400;

//   &:checked {
//     appearance: auto;
//     clip-path: circle(50% at 50% 50%);
//   }
// }
/* CHECKBOX FOR MULTI SELECT */
.custom-checkbox input[type='checkbox'] {
  display: none;
}

.custom-checkbox .checkmark {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1.5px solid $gray-200;
  display: inline-block;
  transition: background-color 0.3s, padding 0.3s;
}

.custom-checkbox input[type='checkbox']:checked + .checkmark {
  background-color: $primary-400;
  padding: 2px;
}

.custom-checkbox .checkmark.checked:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: url('../../images/svgs/checked.svg') no-repeat center center / contain;
}

.custom-form-check {
  position: relative;
  margin: 0.5rem 0;
  padding: 0;

  input[type='radio'] {
    appearance: none;
    background-color: #fff;
    margin-top: 2px;
    color: $gray-300;
    width: 20px;
    height: 20px;
    border: 1.5px solid $gray-200;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $primary-500;
    }

    &:checked {
      background-color: $primary-75;
      border: 1px solid $primary-500;

      &::before {
        transform: scale(1);
      }
    }
  }

  &-label {
    margin: auto 0 0.25rem 1.75rem;
    color: $gray-500;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.26875rem;
  }

  &-input {
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
  }
}

.form-check {
  position: relative;
  display: block;
  margin: 0.5rem 0;
  padding: 0 0.5rem;

  &-input[disabled] ~&-label,
  &-input:disabled ~&-label {
    opacity: 1;
  }

  &-label,
  &-sublabel {
    margin-left: 17.5px;
  }

  &-label {
    font-size: 14px;
    margin-top: 7px;
    font-weight: 500;
    @extend .text-gray-900;
  }

  &-sublabel {
    display: block;
    margin-top: 3px;
    @extend .text-gray-500;
  }

  &-input {
    width: 1.3rem;
    height: 1.3rem;
    position: absolute;


    &.checked-secondary:checked {
      &:checked {
        background-color: $secondary-400;
        border: $secondary-400;
      }
    }

    &.checked-primary:checked {
      &:checked {
        background-color: $primary-400;
        border: $primary-400;
      }
    }

    &.checked-success:checked {
      &:checked {
        background-color: $success-500;
        border: $success-500;
        opacity: 0.8;
      }
    }

    &.disabled,
    &-disabled {
      &:checked {
        background-color: $gray-400 !important;
        border: $gray-400 !important;
      }
    }
  }
}

textarea.form-control {
  resize: none;
  min-height: 45px;
  max-height: 400px;
  padding: 10px 15px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #FCD2C2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #F56630;
    border-radius: 10px;
    border: 3px solid #F56630;
  }
}

.input-group {
  position: relative;
  display: block;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin-top: 10px;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group-text-right,
.input-group-text-left,
.input-group-text-right-sm,
.input-group-text-left-sm {
  font-size: 15px;
  color: $gray-800;
  top: 18px;
  display: inline-block;
  position: absolute;
  z-index: 7;

  img {
    height: 1.25rem;
    width: 1.25rem;
  }

  span {
    margin-left: 0.425rem;
  }
}

.input-group-text-left {
  left: 16px;
  padding-right: 20px;

  &-sm {
    top: 9px !important;
    left: 16px;
  }
}

.input-group-text-right {
  right: 16px;
  padding-left: 20px;

  &-sm {
    top: 9px !important;
    right: 16px;
  }

  img {
    margin-right: 0.425rem;
  }
}

.input-group-text {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: $gray-800;
  text-align: center;
  white-space: nowrap;
  background-color: $white;
  border: 1px solid $gray-300;
  border-radius: 3px;

  input {

    &[type="radio"],
    &[type="checkbox"] {
      margin-top: 0;
    }
  }
}

.form-control-sm {
  .input-group-text-left {
    left: 16px;
    padding-right: 20px;
  }

  .input-group-text-right {
    right: 16px;
    padding-left: 20px;

    img {
      margin-right: 0.425rem;
    }
  }

  .input-group-text {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: $gray-800;
    text-align: center;
    white-space: nowrap;
    background-color: $white;
    border: 1px solid $gray-300;
    border-radius: 3px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: #667185;
  opacity: 1;
  display: block;
  background: url(../../images/svgs/icons/calendar.svg) no-repeat;
  width: 15px;
  height: 15px;
  border-width: thin;
}

select {
  appearance: none;
  height: 1.85rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

@media (max-width: 450px) {
  .form-check-label {
    margin-left: 10px;
  }
}

@media (max-width: 330px) {

  .form-control,
  .css-qy6jp8-container {
    height: 3rem !important;
    font-size: 0.75rem !important;
  }

  .form-control-sm {
    height: 32px !important;
  }
}
