@import "../variables";

.card {
  border-radius: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border: 1px solid $gray-50;
  box-shadow: 0 2px 4px -1px rgba(16, 25, 40, 0.02), 0px 5px 13px -5px rgba(16, 25, 40, 0.05);
  align-items: flex-start;
  gap: 1.25rem;
}

.custom-card{
  min-height: 550px;
}

@media (max-width: 576px) {
  .card {
    padding: 10px;
  }
  .custom-card{
    min-height: 60vh;
  }
}

.card-header {
  width: 100%;
  padding: 0;
  background: transparent;
  border: none;

  .card-title {
    color: $gray-500;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
  }
}

.custom-card-header{
  padding: 36px 36px 0 36px;
  display: flex;
  justify-content: space-between;
}

.card-body {
  flex: 1 1 auto;
  width: 100%;
  padding: 26px 36px;
}

.card-footer {
  background-color: white;
  border-radius: 0 0 0.625rem 0.625rem;
  border-top: 1px solid $gray-200;
}

.card-options {
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-order: 100;
  margin-right: -.5rem;
  color: #a8afc7;
  -ms-flex-item-align: center;
  align-self: center;

  a {
    &:not(.btn) {
      margin-left: 0;
      color: #505069;
      display: inline-block;
      min-width: 1rem;
      padding: 10px 15px;

      &:hover {
        text-decoration: none;
        color: #505069;
      }
    }

  }
}

.card-options {
  a:not(.btn) i {
    font-size: 1rem;
    vertical-align: middle;
  }

  .dropdown-toggle:after {
    display: none;
  }
}

@media (max-width: 576px) {
  .card {
    .card-body, .custom-card-header{
      padding: 10px;
    }
  }
}


