@import "../variables";

.react-datepicker {
  font-family: "Inter", Roboto, sans-serif, "Helvetica Neue", Arial;
  font-size: 12px !important;
  border-radius: 10px;
  border: 1px solid $gray-100 !important;
  padding: 5px;
  margin: 0 10px;
  width: 300px;
  box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.03), 0px 3px 2px -2px rgba(0, 0, 0, 0.06);
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
  height: 100%;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: 600;
  font-size: 15px !important;
  line-height: 23.2px;
}

h2.react-datepicker__current-month {
  text-align: left;
  font-weight: 550;
  letter-spacing: 0.5px;
  margin: 5px 0 10px 10px;
}

.react-datepicker__header {
  display: flex;
  flex-wrap: wrap;
  column-gap: 3px;
  background-color: $white;
  border-bottom: none !important;
}

.react-datepicker__day-names {
  flex: 1 1 100%;
  font-weight: 550;
  line-height: 17.4px;
  margin-top: 10px;
  text-align: center;
}

.react-datepicker__day-name {
  width: 34px;
  height: 34px;
  gap: 5px;
}

.react-datepicker__day--today {
  font-weight: 550;

  &::after {
    content: " ";
    position: absolute;
    margin: 21px -9px 0;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: $primary;
  }
}

.react-datepicker__day,
.react-datepicker__quarter-text,
.react-datepicker__year-text,
.react-datepicker__month-text {
  border: 0.95px solid $gray-100;
  border-radius: 5px;
  padding: 3px 10px 0;
  width: 34px;
  height: 34px;
  gap: 5px;

  &:hover {
    background-color: $gray-100;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--in-selecting-range:not(
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range
),
.react-datepicker__month-text--in-selecting-range:not(
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range
),
.react-datepicker__quarter-text--in-selecting-range:not(
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range
),
.react-datepicker__year-text--in-selecting-range:not(
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range
),
.react-datepicker__day--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  color: black;
  background-color: lighten($primary-50, 2px);

  &:hover {
    background-color: darken($primary-75, 10px) !important;
  }
}

.react-datepicker__day--in-range{
  color: black !important;
  background-color: $primary-50 !important;
}
.react-datepicker__day--selected,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--selected,
.react-datepicker__month-text--selected,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--range-end,
.react-datepicker__day--keyboard-selected,{
  color: black !important;
  background-color: $primary-50 !important;
  border: 1.5px solid $primary-400 !important;

  &:hover {
    background-color: $primary-75 !important;
  }
}

.react-datepicker__day--outside-month {
  color: $gray-300;
  background-color: $gray-75;
}

.react-datepicker__year-select {
  padding: 0.2rem 0.5rem;
  margin: 2px 0;
}

.react-datepicker__navigation {
  top: 15px !important;
}

.react-datepicker__navigation--previous {
  background: url(../../images/svgs/icons/nav-left-arrow-primary.svg) no-repeat;
  width: 20px;
  height: 20px;
  margin: 4px 0 0 240px;
}

.react-datepicker__navigation--next {
  background: url(../../images/svgs/icons/nav-right-arrow-primary.svg) no-repeat;
  width: 20px;
  height: 20px;
  margin: 4px 10px 0 25px;
}

.react-datepicker__navigation-icon {
  &::before {
    border-style: none;
  }
}

.react-datepicker__year-wrapper,
.react-datepicker__month-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 270px;
  max-width: 100%;
}

.react-datepicker__year,
.react-datepicker__year-text,
.react-datepicker__quarter,
.react-datepicker__quarter-text {
  padding-top: 10px;
}

.react-datepicker-popper {
  z-index: 9999;
  top: -10px;
}

.react-datepicker__month-container {
  margin-bottom: 10px;
}

.react-datepicker__children-container {
  width: 14.5rem;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: $gray-50;
  color: $gray-50;
}

.react-datepicker-popper .react-datepicker__triangle {
  stroke: $gray-200;
}

.react-datepicker__year-read-view--selected-year {
  display: none;
}

.react-datepicker__year-read-view--down-arrow {
  border-color: $primary;
  margin: -1px 17px 0 0;
  border-width: 1.5px 1.5px 0 0;

  &:hover {
    border-color: $primary;
  }
}

.react-datepicker__year-dropdown {
  width: 100px;
  background-color: $white;
  border: 1.5px solid $gray-200;
  border-radius: 10px;
  transform: translateY(-1px);
  transition: 1s ease-in-out;
  box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.1),
  0 5px 10px 5px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.react-datepicker__year-option {
  font-family: "Inter", Roboto, sans-serif, "Helvetica Neue", Arial;
  font-size: 12px !important;
  padding: 5px 0;

  &:first-child,
  &:last-child {
    padding: 0;
  }

  &:hover {
    background-color: $primary-50;
  }
}

.react-datepicker__year-option {
  .react-datepicker__navigation {
    display: none;
  }
}
