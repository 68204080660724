@import "../variables";

.custom-toast-container {
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  row-gap: 12px;
  z-index: 999999;
}

.custom-toast {
  position: relative;
  width: 24rem;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  background-color: $white;
  margin-bottom: 0.75rem;
  border-left: 0.375rem solid $primary;
  border-top: 1px solid #E4E7EC;
  border-right: 1px solid #E4E7EC;
  border-bottom: 1px solid #E4E7EC;
  overflow: hidden;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  animation: slide-in 0.4s ease-in-out forwards;


  img {
    margin: 4.5px 16px auto 0;
  }

  h6, p {
    font-size: 0.875rem;
    font-style: normal;
  }

  h6 {
    color: $gray-900;
    font-weight: 600;
    line-height: 1.26875rem;
    margin-bottom: 5px;

  }

  p {
    color: $gray-600;
    font-weight: 400;
    line-height: 1.25rem;
  }
}

.custom-toast-dismissed {
  animation: slide-out 0.4s ease-in-out forwards;
}

.toast-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: transparent;

  &-bar {
    animation: progress-bar-stripes 4s linear forwards;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
    width: 100%;
  }

  100% {
    background-position: 0 0;
    width: 0;
  }
}

@media (max-width: 768px) {
  .custom-toast {
    width: 100%;
  }
}
